








































































































import { Component, Vue } from 'vue-property-decorator';

import FlagshipProfilingStepFinancialFreedomViewModel
  from
  '@/vue-app/view-models/components/flagship/flagship-profiling/flagship-profiling-step-financial-freedom-view-model';

@Component({ name: 'FlagshipProfilingStepFinancialFreedom' })
export default class FlagshipProfilingStepFinancialFreedom extends Vue {
  view_model = Vue.observable(
    new FlagshipProfilingStepFinancialFreedomViewModel(this),
  );

  created() {
    this.view_model.initialize();
  }
}
