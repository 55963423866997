import TYPES from '@/types';
import Vue from 'vue';

// Application
import UpdateInvestorProfileCommand
  from '@/modules/flagship/investor-profile/investor-profile/application/commands/update-investor-profile-command';
import GetInvestorProfileQuery
  from '@/modules/flagship/investor-profile/investor-profile/application/queries/get-investor-profile-query';
import GetCustomerContractedInvestmentProductsQuery
  from '@/modules/flagship/customer-contracted-investment-products/application/queries/get-customer-contracted-investment-products-query';
import GetInvestmentProductsQuery
  from '@/modules/flagship/catalogs/investment-products/application/queries/get-investment-products-query';

// Domain
import {
  InvestorProfileEntity,
} from '@/modules/flagship/investor-profile/investor-profile/domain/entities/investor-profile-entity';
import Inject from '@/modules/shared/domain/di/inject';
import { MessageNotifier } from '@/modules/shared/domain/notifiers/message_notifier';
import Translator from '@/modules/shared/domain/i18n/translator';
import { Values } from '@/modules/shared/domain/i18n/types';

export default class FlagshipProfilingStepFinancialFreedomViewModel {
  @Inject(TYPES.UPDATE_INVESTOR_PROFILE_COMMAND)
  // eslint-disable-next-line max-len
  private readonly update_investor_profile_command!: UpdateInvestorProfileCommand;

  @Inject(TYPES.GET_INVESTOR_PROFILE_QUERY)
  private readonly get_investor_profile_query!: GetInvestorProfileQuery;

  @Inject(TYPES.GET_CUSTOMER_CONTRACTED_INVESTMENT_PRODUCTS_QUERY)
  private readonly get_contracted_products_query!: GetCustomerContractedInvestmentProductsQuery;

  @Inject(TYPES.GET_INVESTMENT_PRODUCTS_QUERY)
  private readonly get_investment_products!: GetInvestmentProductsQuery;

  @Inject(TYPES.NOTIFIER)
  readonly messageNotifier!: MessageNotifier;

  @Inject(TYPES.I18N)
  readonly translator!: Translator;

  private readonly view!: Vue;

  readonly i18n_namespace = 'components.flagship.flagship-profiling.flagship_profiling_step_financial_freedom';

  emergency_fund_option = null;

  has_retirement_fund = null;

  has_sowos_pocket_contracted = false;

  has_sowos_wealth_contracted = false;

  emergency_fund_options = [
    { value: 'less_than_3_months_of_income', label: 'Menor a 3 meses de ingresos' },
    { value: 'more_than_3_months_of_income', label: 'Mayor a 3 meses de ingresos' },
    { value: '', label: 'No cuento con él' },
  ];

  has_retirement_fund_options = [
    { value: true, label: 'Si' },
    { value: false, label: 'No' },
  ]

  private investor_profile_entity: InvestorProfileEntity = {
    id: '',
    customer_id: sessionStorage.getItem('user_id')!,
    custom_goals: {},
    is_showed: false,
    person_id: '',
    employment_situation_id: '',
    level_of_education_id: '',
    recommended_investment_product_id: '',
    emergency_fund_availability_id: '',
    has_emergency_fund: true,
    net_monthly_expenses: 0,
    elder_dependent_count: 0,
    is_completed: true,
    child_dependent_count: 0,
    pet_dependent_count: 0,
    net_monthly_income: 0,
    active_goals_count: 0,
    goals_completed: false,
    emergency_fund_locations: {},
    retirement_fund_locations: {
      retirement_amount: 0,
    },
    exit_pool: {},
    finance_related_job: false,
    income_behavior_in_following_year_id: '',
    emergency_fund_amount: 0,
    emergency_fund_saved: '',
    has_retirement_fund: null,
  }

  public constructor(view: Vue) {
    this.view = view;
  }

  get is_disabled() {
    return this.has_retirement_fund === null || this.emergency_fund_option === null;
  }

  translate = (message: string, values?: Values) => this.translator.translate(`${this.i18n_namespace}.${message}`, values);

  loadInvestorProfile = async () => {
    try {
      this.investor_profile_entity = await this.get_investor_profile_query.execute(false);
    } catch {
      this.messageNotifier.showErrorNotification(
        this.translate('errors.load_investor_profile'),
      );
    }
  }

  loadCustomerContractedProductsInformation = async () => {
    try {
      const {
        has_sowos_wealth_contracted,
        has_sowos_pocket_contracted,
      } = await this.get_contracted_products_query
        .execute();
      this.has_sowos_wealth_contracted = has_sowos_wealth_contracted;
      this.has_sowos_pocket_contracted = has_sowos_pocket_contracted;
    } catch {
      this.messageNotifier.showErrorNotification(this.translate('errors.load_customer_contracted_products'));
    }
  }

  getRecommendedProduct = async () => {
    const investment_products = await this.get_investment_products.execute();
    if (this.has_retirement_fund) {
      if (this.emergency_fund_option === '') {
        if (this.has_sowos_pocket_contracted) {
          return investment_products.find(
            (product) => product.name === 'sowos_wealth',
          );
        }
        return investment_products.find(
          (product) => product.name === 'sowos_pocket',
        );
      }
      return investment_products.find(
        (product) => product.name === 'sowos_wealth',
      );
    }
    return investment_products.find(
      (product) => product.name === 'sowos_wealth',
    );
  }

  updateInformation = async () => {
    try {
      this.view.$emit('loadingStep', true);

      const recommended_investment_product = await this.getRecommendedProduct();

      const patch_payload = {
        id: this.investor_profile_entity.id,
        has_retirement_fund: this.has_retirement_fund || false,
        emergency_fund_saved: this.emergency_fund_option,
        has_emergency_fund: this.emergency_fund_option !== '',
        recommended_investment_product_id: recommended_investment_product?.id || '',
        is_completed: true,
      };
      await this.update_investor_profile_command.execute(patch_payload);
      this.view.$emit('endProcess', recommended_investment_product?.name);
      return true;
    } catch {
      this.messageNotifier.showErrorNotification(
        this.translate('errors.update_profile'),
      );
      return false;
    } finally {
      this.view.$emit('loadingStep', false);
    }
  }

  initialize = async () => {
    this.view.$emit('loadingStep', true);
    await this.loadInvestorProfile();
    await this.loadCustomerContractedProductsInformation();
    this.view.$emit('loadingStep', false);
  }
}
